/* @import url('https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */



/* New Css */
/* .addCardInfo {
  padding: 12px 20px 20px;
} */
.addCardInfo {
  margin: 32px 0px 35px;
}

.cardBnrLogo {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 90%;
  margin: 0 auto;
}

.cardBnrDetail {
  margin-bottom: 24px;
  position: relative;
}

.cardBnrLogo img {
  height: 63px;
  width: initial !important;
  border-radius: 12px !important;
  box-shadow: 0px 5px 7px 2px #c68b8563;
}

.cardBnrDetail img {
  width: 100%;
  border-radius: 0 0 20px 20px;
}

.addCardDetail label {
  display: block;
  color: #242424;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 12px;
  font-family: 'OmnesSemiBold';
}

.addCardLogo img {
  height: 35px;
  cursor: pointer;
}

.addCardLogo {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.addCardDetailUpr {
  background: #ffffff96 !important;
  border-radius: 20px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 4%);
}

.addCardDetail {
  border-radius: 20px;
  background: #fff !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10196078431372549);
  padding: 26px 22px;
}

.addCardDetail p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #242424;
  margin-top: 6px;
  margin-bottom: 0;
  padding: 0 40px;
  opacity: 0.5;
  letter-spacing: .02em;
  font-family: 'omnesregular';
}

.addCardDetail h3 {
  display: block;
  color: #242424;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'OmnesSemiBold';
  cursor: pointer;
}

.addCardDetailTD {
  border-radius: 0px 0px 20px 20px;
  padding: 0px 25px 18px;
  margin-top: 15px;
  text-align: center;
}

.addCardDetailTD p {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15.5px;
  text-align: center;
  color: #242424;
  opacity: 50%;
  font-family: 'omnesregular';
  margin: 0px;
}

.addCardDetailTD p b {
  font-family: 'OmnesMedium';
}

.checkWithFB a {
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: linear-gradient(90deg, #66B0C4 7.5%, #4267B2 77%, #4267B2 100%);
  border-radius: 30px !important;
  font-family: 'OmnesSemiBold';
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #fff !important;
  padding: 13px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0 20px 14px -21px #000000a3;
  cursor: pointer;
}

.checkWithFB img {
  height: 24px;
  margin-top: -2px;
}

.checkWithG a {
  box-shadow: 0 18px 20px -18px #ff5a6c;
  background: #f47c6a;
}

.checkWithUpr {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 22px;
}

.emailInput input {
  width: 100%;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  outline: none;
  padding: 12px 12px;
  box-sizing: border-box;
  font-family: 'OmnesMedium'; 
}

.emailInput input::-webkit-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.emailInput input:-ms-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.emailInput input::-moz-placeholder {
  color: #242424;
  opacity: 0.5;
}

.emailInput input:-moz-placeholder {
  color: #242424;
  opacity: 0.5;
}

img.heartVector {
  position: absolute;
  left: 10%;
  bottom: 0px;
  height: 22px;
  cursor: initial !important;
}

img.StarUnion {
  position: absolute;
  right: 14%;
  top: -2px;
  height: 12px;
  cursor: initial !important;
}

.emailInput {
  margin-top: 32px;
  margin-bottom: 22px;
}

/* ---- */
.addChkDetail label {
  display: block;
  color: #242424;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
  font-family: 'OmnesSemiBold';
}

.addChkDetail p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(36, 36, 36, 0.50);
  margin-top: 6px;
  margin-bottom: 0;
  padding: 0 20px;
  letter-spacing: .02em;
  font-family: 'OmnesRegular';
  opacity: initial;
}

.addChkDetail p span {
  color: #F47C6A;
  font-weight: 700;
  font-family: 'OmnesMedium';
  display: block;
}

.checkWithTimer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 32px 0px 22px;
  margin-bottom: 10px;
  padding: 0px 21px;
}

.checkWithResend a {
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #F5F5F5;
  border-radius: 30px !important;
  font-family: 'OmnesSemiBold';
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: rgba(36, 36, 36, 0.50);
  padding: 13px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.checkWithResendBk {
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  border-radius: 30px !important;
  font-family: 'OmnesSemiBold';
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 13px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0 20px 19px -21px #C3C3C3;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  color: #4E4E4E;
}

.checkWithResendClr a {
  background: #F47C6A;
  box-shadow: 0 18px 20px -18px #ff5a6c;
  color: #fff !important;
}

.editEmailInr span {
  color: #F47C6A;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.128px;
  font-family: 'OmnesSemiBold';
}

.editEmailInr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.editEmailInr a {
  display: inline-flex;
}


.checkOtp {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 18px;
}

.checkOtp a {
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  border-radius: 30px !important;
  font-family: 'OmnesSemiBold';
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #fff !important;
  padding: 13px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0 18px 20px -18px #ff5a6c;
  background: #f47c6a;
  cursor: pointer !important;
}

.optFields input {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 42px;
  height: 42px;
  padding: 6px;
  font-size: 24px;
  text-align: center;
  outline: none;
  margin: 0px 5px;
}

.optFields {
  display: flex;
  padding: 0px 25px;
  margin: 15px 0px 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.setTimer label {
  color: rgba(36, 36, 36, 0.50);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  font-family: 'OmnesRegular';
  margin-bottom: 0px;
  display: block;
}

.verifyHead label {
  color: #4E4E4E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-family: 'OmnesSemiBold';
  margin-bottom: 22px;
  display: block;
  line-height: 21px;
}

.editEmail label {
  display: block;
  color: #242424;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 12px;
  font-family: 'OmnesSemiBold';
}

.verifyHead {
  position: relative;
}

.verifyHead img {
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
}

.addCardDetailUpr2 {
  background: #ffffff96;
  border-radius: 20px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 4%);
}

.addCardDetail2 {
  border-radius: 20px;
  background: #fff !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10196078431372549);
  padding: 26px 22px;
}

.verifyHeadOne label {
  width: 68%;
  color: #4E4E4E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-family: 'OmnesSemiBold';
  margin-bottom: 0px;
  display: block;
  line-height: 21px;
}

.verifyHeadOne div {
  width: 16%;
}

.verifyHeadOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.verifyHeadOne a {
  border-radius: 12px;
  background: #F47C6A;
  display: inline-flex;
  padding: 5px 12px;
  align-items: center;
  color: #FFF !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  font-family: 'OmnesSemiBold';
}

.createAc input {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 47px;
  padding: 6px 13px;
  text-align: left;
  outline: none;
  margin-bottom: 12px;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
}

.createAcBtns {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 12px;
}

.createAcBtns a {
  width: 50%;
  display: block;
  cursor: pointer !important;
}

.createAcBtns input {
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 51px;
  padding: 6px 13px;
  text-align: center;
  outline: none;
  color: #242424;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
  cursor: pointer;
}

.createAc input::-webkit-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.createAc input:-ms-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.createAc input::placeholder {
  color: #242424;
  opacity: 0.5;
}

/* .createAcBtns a:first-child input {
  color: #AE86E0;
} */

.femaleseleted{
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  padding: 6px 13px;
  text-align: center;
  min-height: 51px;
  color: #AE86E0;
  
}
.femaleseleted h4{
  text-align: center;
  font-weight: 600;
  margin-top: 6px;
  font-size: 18px;

  font-family: 'OmnesMedium';


}

.femaleseleted1{
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  padding: 6px 13px;
  text-align: center;
  min-height: 51px;
  color: #66B0C4;
  
}
.femaleseleted1 h4{
  text-align: center;
  font-weight: 600;
  margin-top: 6px;
  font-size: 18px;
  font-family: 'OmnesMedium';
}

.createAcBtns a:first-child input:hover {
  background: #AE86E0 !important;
  color: #fff !important;
}

.createAcBtns a:last-child input {
  color: #66B0C4;
}

.createAcBtns a:last-child input:hover {
  background: #66B0C4 !important;
  color: #fff !important;
}


.createAcBtns a:first-child:hover div {
  background: #AE86E0 !important;
  color: #ffffff !important;
}

.createAcBtns .femaleseleted.background {
  background: #AE86E0 !important;
  color: #ffffff !important;
}

.createAcBtns a:last-child:hover div {
  background-color: #66B0C4 !important;
  color:#ffffff !important;
}

.createAcBtns .background{
  background-color: #66B0C4 !important;
  color:#ffffff !important;
}

.createAcBtnsUpr label {
  opacity: 0.5;
  color: #242424;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'OmnesRegular';
  display: block;
  margin-bottom: 8px;
  margin-top: 12px;
}

.addCardDetailTD2 {
  padding: 6px 16px 0px;
}

.acStreetAddres input {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 47px;
  padding: 6px 13px;
  text-align: left;
  outline: none;
  margin-bottom: 12px;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
  z-index: 111;
  position: relative;
}

.acStreetAddres input::-webkit-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.acStreetAddres input:-ms-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.acStreetAddres input::placeholder {
  color: #242424;
  opacity: 0.5;
}

.acStreetAddres label {
  opacity: 0.5;
  color: #242424;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'OmnesRegular';
  display: block;
  margin-bottom: 8px;
  margin-top: 0px;
}

.acCntryAddres input {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 47px;
  padding: 6px 13px;
  text-align: left;
  outline: none;
  margin-bottom: 12px;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
}

.acCntryAddres {
  position: relative;
}

.acCntryAddres img {
  position: absolute;
  right: 15px;
  top: 16px;
}


.actvContentInfo input, .actvContentInfo select {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 47px;
  padding: 6px 13px;
  text-align: left;
  outline: none;
  margin-bottom: 12px;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
}

.actvContentInfo input::-webkit-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.selectTabType a {
  border-radius: 20px;
  width: 101px;
  height: 41px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #242424;
  opacity: 0.5;
  font-size: 14px;
  font-family: 'OmnesMedium';
  cursor: pointer;
}

.selectTabType a.active {
  background: #F47C6A;
  color: #FFF !important;
  opacity: 1;
}

.selectTabType a:hover {
  background: #F47C6A;
  color: #FFF !important;
  opacity: 1;
}

.selectTabType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 15px;
  gap: 4px;
}

.acTabSection {
  border-radius: 15px;
  border: 1.5px solid rgba(36, 36, 36, 0.20);
  background: #FCFCFC;
  padding: 16px 14px;
  margin-top: 6px;
  margin-bottom: 22px;
}

.actvContentInfo div:last-child input {
  margin-bottom: 0px;
}

.aprtContentInfo {
  position: relative;
}

.aprtContentInfo img {
  position: absolute;
  right: 15px;
  top: 16px;
}

.adChildRen input {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 50px;
  padding: 6px 13px;
  text-align: left;
  outline: none;
  margin-bottom: 16px;
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
}

.adChildExpt input {
  border-color: #AE86E0;
  border-width: 1.5px;
  border-radius: 30px;
  color: #AE86E0;
}

.adChildExpt input::-webkit-input-placeholder {
  color: #AE86E0;
}

.adChildExpt input:-ms-input-placeholder {
  color: #AE86E0;
}

.adChildExpt input::placeholder {
  color: #AE86E0;
}


.adChildExist input {
  border-color: #FDD500;
  border-width: 1.5px;
  border-radius: 30px;
  color: #FDD500;
}

.adChildExist input::-webkit-input-placeholder {
  color: #FDD500;
}

.adChildExist input:-ms-input-placeholder {
  color: #FDD500;
}

.adChildExist input::placeholder {
  color: #FDD500;
}

.adChildRen div {
  position: relative;
}

.adChildRen a {
  border-radius: 42px;
  display: inline-block;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  color: #FFF !important;
  font-size: 12px;
  font-style: normal;
  letter-spacing: -0.024px;
  cursor: pointer;
  position: absolute;
  right: 6.5px;
  top: 6.5px;
  line-height: 21px;
  font-family: 'OmnesMedium';
}

.adChildExpt a {
  background: #AE86E0;
}

.adChildExist a {
  background: #FDD500;
}

.adChildRen a span {
  font-size: 17px;
  margin-left: 3px;
}

.checkOtpDisable a {
  background: #F5F5F5;
  color: rgba(36, 36, 36, 0.50) !important;
  box-shadow: initial;
}


.expChildExpnd label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 114.205%;
  color: #AE86E0;
  text-align: left;
  display: block;
  margin-bottom: 12px;
  margin-top: 0px;
  font-family: 'OmnesSemiBold';
}

.expChildExpndUpr {
  border-radius: 15px;
  border: 1.5px solid var(--Purple, #AE86E0);
  background: #FFF !important;
  padding: 15px 15px 15.402px 15px;
  margin-bottom: 25px;
}

.expChildExpndInr {
  padding: 7px;
  border-radius: 9px;
  background: rgba(174, 134, 224, 0.12);
}

.expChildExpnd input[type="text"] {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 100%;
  min-height: 48px;
  padding: 6px 13px;
  text-align: left;
  outline: none;
  margin-bottom: 10px;
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'OmnesMedium';
}

.expChildExpnd input::-webkit-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.expChildExpnd input:-ms-input-placeholder {
  color: #242424;
  opacity: 0.5;
}

.expChildExpnd input::-moz-placeholder {
  color: #242424;
  opacity: 0.5;
}

.expChildExpnd input:-moz-placeholder {
  color: #242424;
  opacity: 0.5;
}

.expChildBtns input[type="button"] {
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  width: 48%;
  min-height: 48px;
  padding: 6px 5px;
  text-align: center;
  outline: none;
  margin-bottom: 10px;
  color: #919191;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  box-sizing: border-box;
  line-height: 20px;
  font-family: 'OmnesMedium';
  cursor: pointer;
}

.expChildBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.expChildSbmt input[type="button"] {
  color: rgba(36, 36, 36, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.024px;
  font-family: 'OmnesSemiBold';
  width: 50%;
  border: none;
  background: transparent;
  outline: none;
  min-height: 42px;
  border-radius: 32px;
  cursor: pointer;
}

input.expChildSbmtActv {
  background: #AE86E0 !important;
  color: #ffffff !important;
}

.adexistUserInfoLft {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85%;
  flex-wrap: nowrap;
}
.adexistUserInfoLft span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40.181px;
  height: 40.023px;
  background: #fdd5005e;
  border-radius: 36px;
  color: #fdd500;
  font-size: 19.835px;
  font-style: normal;
  font-weight: 600;
  line-height: 114.205%;
  margin-right: 7px;
  font-family: 'OmnesSemiBold';
}

.existChildExpLstInr {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.existChildExpLstInr a {
  border-radius: 42px;
  display: inline-block;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  color: #efefc2;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.024px;
  cursor: pointer;
  font-family: 'OmnesMedium';
  line-height: 21px;
  background: #FDD500;
}
.existChildExpLstInr label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 114.205%;
  color: #FDD500;
  text-align: left;
  display: block;
  margin: 0px;
  font-family: 'OmnesSemiBold';
}
.existChildExpndList {
  border-radius: 15px;
  border: 1.5px solid var(--Purple, #FDD500);
  background: #FFF !important;
  padding: 15px 15px 15.402px 15px;
  margin-bottom: 25px;
}
.expChildExpndYelow {
  border: 1.5px solid #FDD500;
}

.expChildExpndYelow .expChildExpndInr {
  background: rgba(253, 213, 0, 0.12);
}

.expChildExpndYelow input.expChildSbmtActv {
  background: #FDD500 !important;
}

.expChildExpndList {
  border-radius: 15px;
  border: 1.5px solid var(--Purple, #AE86E0);
  background: #FFF !important;
  padding: 15px 15px 15.402px 15px;
  margin-bottom: 25px;
}

.expChildExpLstInr label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 114.205%;
  color: #AE86E0;
  text-align: left;
  display: block;
  margin: 0px;
  font-family: 'OmnesSemiBold';
}

.expChildExpLstInr a {
  border-radius: 42px;
  display: inline-block;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  color: #FFF !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.024px;
  cursor: pointer;
  font-family: 'OmnesMedium';
  line-height: 21px;
  background: #AE86E0;
}

.expChildExpLstInr {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.expChildExpLstInr a {
  border-radius: 42px;
  display: inline-block;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  color: #FFF !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.024px;
  cursor: pointer;
  font-family: 'OmnesMedium';
  line-height: 21px;
  background: #AE86E0;
}

.expChildExpLstInr a span {
  font-size: 17px;
  margin-left: 3px;
}

.expChildExpndYelow .expChildExpnd label {
  color: #FDD500;
}

.adUserInfoLft label {
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.205%;
  letter-spacing: -0.28px;
  font-family: 'OmnesMedium';
  margin-bottom: 8px;
  display: block;
}

.adUserInfoLft label {
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.205%;
  letter-spacing: -0.28px;
  font-family: 'OmnesMedium';
  margin-bottom: 2px;
  display: block;
}

.adUserInfoLft p {
  color: #242424;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.205%;
  letter-spacing: -0.24px;
  font-family: 'OmnesMedium';
  margin: 0px;
  opacity: 0.5;
}

.adUserInfoLft span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40.181px;
  height: 40.023px;
  background: #d6c2ef;
  border-radius: 36px;
  color: #927BC3;
  font-size: 19.835px;
  font-style: normal;
  font-weight: 600;
  line-height: 114.205%;
  margin-right: 7px;
  font-family: 'OmnesSemiBold';
}

.adUserInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF !important;
  padding: 10px 12px;
  box-sizing: border-box;
  margin-top: 14px;
}

.adUserInfoLft {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85%;
  flex-wrap: nowrap;
}

.adUserInfoRght {
  width: 15%;
  text-align: right;
  cursor: pointer;
}

.adUserInfoSpc {
  margin-bottom: 12px;
}

.addChildDateIcon .MuiInput-underline {
  width: 100%;
  border-bottom: 0px !important;
}

.addChildDateIcon .MuiInput-underline:before,
.addChildDateIcon .MuiInput-underline:after {
 content: initial !important;
 border-bottom: none !important;
}

.addChildDateIcon div {
  width: 100% !important;
}

.expChildBtnsAdd input.active {
  background: #AE86E0 !important;
  color: #ffffff !important;
}
.existChildBtns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  width: 100%;
  margin-top: 10px;
}
.existChildBtns input.active {
  background: #FDD500 !important;
  color: #ffffff !important;
}

.adexistUserInfoLft label {
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.205%;
  letter-spacing: -0.28px;
  font-family: 'OmnesMedium';
  margin-bottom: 2px;
  display: block;
}

.adexistUserInfoLft p {
  color: #242424;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.205%;
  letter-spacing: -0.24px;
  font-family: 'OmnesMedium';
  margin: 0px;
  opacity: 0.5;
}

.existChildExpLstInr a {
  color: #ffffff !important;
}

.expChildExpndInr h3 {
  text-align: center;
  margin-left: 0px !important;
  margin-top: 9px !important;
  display: block;
  font-size: 14px;
  font-family: 'OmnesRegular' !important;
  color: #FDD500 !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.custom_editchildBody {
  font-family: 'Montserrat', sans-serif;
  min-height: 538px;
}
.custom_editchildBody.modal-body .editchild_content button:last-child {
  background: transparent !important;
  color: #4e4e4e !important;
}
.buzSub {
  display: flex;
  background: hsla(0, 0%, 100%, .5);
  margin-top: -13px !important;
  margin-bottom: 20px !important;
  padding: 24px 5px 12px;
  border-radius: 0 0 20px 20px;
}
.buzSubBtn {
  width: 33.333%;
  text-align: center;
}
.buzSubBtn button {
  color: rgba(36, 36, 36, .5);
  outline: none !important;
  padding: 15px 4px;
  background: #fff;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  width: 95%;
  margin: 0 auto;
  font-family: "OmnesSemiBold";
  border: 1px solid rgba(0, 0, 0, .1);
}

.buzSubActv {
  color: #242424 !important;
  background: #fff !important;
  opacity: 1;
}
.loaderbgBlack{
  background-color: #0908082e;
    min-height: 100vh;
    min-width: 374px;
    display: flex;
    align-items: center;
    justify-content: center;

}
@media screen and (max-width: 600px) {
  .loaderbgBlack{
    min-width: 100%;
  }
}

.ggl_fb_success_msg{
  font-size: 18px;
  line-height: 35px;
  font-weight: 600;
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 0;
  font-family: "OmnesSemiBold";
}
.ggl_scs_div{
  display: flex;
  flex-direction: column;
}
.success_class{
  position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-self: center;
}
.success_class:before {
  background-image: url(/images/rght2.png);
  width: 40px;
  height: 40px;
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  /* margin: 0 auto; */
  /* display: table; */
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #64c09b;
  border-radius: 36px;
  background-color: #64c09b;
}
.term_color_blue{
  color: #007bff;
}
.justify_content_end{
  justify-content: end;
}
.loader_center_fixed{
  position: absolute;
  top: 40%;
  left: 33%;
}
.checkWithFB.checkWithG {
  margin-top: 15px;
}
.checkOtp a:first-child {
  margin-bottom: 15px;
  display: block;
}
.skipbtn{
  outline: none !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    border-radius: 30px !important;
    font-family: 'OmnesSemiBold';
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 13px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer !important;
    box-shadow: 0 20px 19px -21px #c3c3c3 !important;
    border: 1px solid #0000001a;
    background: #fff !important;
    color: #4e4e4e !important;
}

button:disabled {
  background-color: #cccccc !important; /* Disabled color */
  color: #666666 !important; /* Disabled text color */
  cursor: not-allowed !important;
}