.mySwiper {
    padding-bottom: 18% !important;
}

.cstmSwiper {
    width: 395px;
    margin: 0 auto;
    background: #dfdfdf;
}

.cstmSwiper .swiper {
    width: 100%;
    padding-bottom: 35px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-slide img {
    box-shadow: -11px 12px 15px 2px #dcdcdc;
    border-radius: 50%;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
    background-image: none !important;
}

.swiper-button-next {
    right: 0px !important;
    height: 102px !important;
    width: 70px !important;
    top: 70px !important;
    opacity: 0 !important;
}

.swiper-button-prev {
    left: 0px !important;
    height: 102px !important;
    width: 77px !important;
    top: 70px !important;
    opacity: 0 !important;
}

.swiper-slide.swiper-slide-active {
    min-height: 185.5px;
    min-width: 185.5px;
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
}

.swiper-slide-prev,
.swiper-slide-next {
    /* margin-top: 10%;
    width: 130px !important; */
}

.cstmSwiper .swiper-slide-prev {
    transform: translate3d(0px, 0px, -128.7468px) rotateX(0deg) rotateY(52deg) scale(1) !important;
}

.cstmSwiper .swiper-slide-next {
    transform: translate3d(0px, 0px, -128.7468px) rotateX(0deg) rotateY(125deg) scale(1) !important;
}

.swiper-3d .swiper-wrapper {
    transform-style: preserve-3d;
    align-items: center;
    /* margin-left: -5.5%; */
}

.sharegain_swiper .swiper-slide-next img,
.sharegain_swiper .swiper-slide-prev img {
    width: 117px !important;
    height: 117px !important;
    margin-top: 0px !important;
    transform: scale(1) !important;
}

.sharegain_swiper img {
    /* box-shadow: initial !important;
    border-radius: 0px !important; */
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
    background-image: none !important;
}

.swiper-slide.swiper-slide-active {
    transform: initial !important;
    height: 185.5px !important;
    width: 185.5px !important;
    margin-right: 8px;
    /* margin: 0px 16px; */
}

.swiper-slide.swiper-slide-prev,
.swiper-slide.swiper-slide-next {
    margin-top: 45px;
}

.swiper-slide.swiper-slide-prev {
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(34deg) scale(1) !important;
}

.swiper-slide.swiper-slide-next {
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(36deg) scale(1) !important;
}

.swiper-slide .gain_extra {
    display: none;
}

.swiper-slide.swiper-slide-active .gain_extra {
    display: block !important;
}


.swiper-slide.swiper-slide-active img.slideImg {
    height: 185.5px !important;
    width: 185.5px !important;
}

.swiper-3d .swiper-wrapper {
    align-items: flex-start !important;
}

/* .swiper-slide.swiper-slide-prev {
    margin-left: 0px !important;
} */

.swiper-slide {
    opacity: 0;
}
.swiper-slide.swiper-slide-prev, .swiper-slide.swiper-slide-active,
.swiper-slide.swiper-slide-next {
    opacity: 1;
}

span.swiper-pagination-bullet {
    pointer-events: none;
}


@media (max-width: 600px) {
  /* .swiper-slide.swiper-slide-next .program_slides.slides {
    display: flex;
    justify-content: flex-end;
  } */
  .swiper-slide.swiper-slide-prev {
    margin-left: -22px;
  }
  .mySwiper {
    padding-bottom: 22% !important;
  }
}

@media (min-width: 767px) {
    .swiper-slide {
        padding-bottom: 0% !important;
    }
}

@media screen and (max-width: 460px) {
    .addSliderBg {
        min-height: 390px;
        height: initial;
    }
}


