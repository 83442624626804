/* New Style added for NExt Home screen */
/* @font-face {
    font-family: 'OmnesRegular';
    src: url('/fonts/Omnes-Regular.woff') format('woff');
    font-weight: normal;
}
@font-face {
    font-family: 'OmnesMedium';
    src: url('/fonts/Omnes-Medium.woff') format('woff');
    font-weight: 500; 
}
@font-face {
    font-family: 'OmnesSemiBold';
    src: url('/fonts/Omnes-SemiBold.woff') format('woff');
    font-weight: 600; 
}
@font-face {
    font-family: 'OmnesBold';
    src: url('/fonts/Omnes-Bold.woff') format('woff');
    font-weight: bold; 
} */

.siteSidebar {
    background-image: url("/images/desktopBg2.png");
    top: 24%;
    background-repeat: no-repeat;
    padding: 5px 64px 5px 25px;
    background-size: contain;
    height: 365px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 251px;
    position: fixed;
    bottom: 83px;
    right: 0px;
    left: 622px;
    cursor: pointer;
    margin: 0 auto;
}

.addPurpalLineBg {
    background-image: url('/images/purpalLine2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: right;
    padding-bottom: 22px;
    height: 69.9px;
}
.addPurpalLineBgInr { 
    background-image: url('/images/pulpleLineouter.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: right;
    padding-bottom: 22px;
}

.addSliderBg {
    position: relative;
    text-align: center;
    background-image: url('/images/mainLogoutBg.svg') !important;
    background-size: cover;
    background-repeat: no-repeat !important;
    background-position: bottom center;
}
/* End of New Style added for NExt Home screen */

.circleTransition {
    background: #fc6071;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    /* border-top-left-radius: 600px;
    border-top-right-radius: 600px; */
    bottom: 67px;
    height: 50px;
    width: 100%;
    z-index: 1;
    visibility: hidden;
    /*border: 50px solid #f47c6a; */
}
.jump_login button {
    z-index: 2;
}

.box {
    position: relative;
    display: block;
    height: 10px;
    width: 10px;
    margin: 10px;
}
  .grey {
    background: gray;
}
.orange {
    background: orange;
}
.green {
    background: #427e3a;
}
.dot {
    position: absolute;
    height: 4px;
    width: 4px;
    top: 0;
    background: #ba3627;
    left: 0;
}

.awardcup_main {
    position: relative;
    display: inline-block;
    }
    img.awardcup {
    position: absolute;
    top: 37px;
    right: 35px;
    }

.boarding_cup{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.boarding_inner {
    min-height: 0px!important;
    height: 100px;
    width: 100px;
    background-color: #fde9e9;
    border-radius: 10px;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: transform 0.1s;
}

p.pink_underline {
    width: 15px;
    height: 3px;
    background: #ff6374;
    transform: rotate(0deg);
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
}

span.rb_dot {
    width: 7px;
    height: 2px;
    background: #ff6374;
    transform: rotate(10deg);
    position: absolute;
    bottom: 3px;
    right: -15px;
    margin: auto;
}

span.lb_dot {
    width: 6px;
    height: 2px;
    background: #ff6374;
    transform: rotate(0deg);
    position: absolute;
    bottom: 4px;
    left: -15px;
    margin: auto;
}

span.rt_dot {
    width: 10px;
    height: 2px;
    background: #ff6374;
    transform: rotate(-40deg);
    position: absolute;
    bottom: 12px;
    right: -16px;
    margin: auto;
}

span.lt_dot {
    width: 10px;
    height: 2px;
    background: #ff6374;
    transform: rotate(30deg);
    position: absolute;
    bottom: 12px;
    left: -18px;
    margin: auto;
}
.boarding_cup{
    opacity: 0;
}
.op-0{
    opacity: 0;
}

.boarding_inner a{
    background: none!important;
}


.layers {
    position: relative;
}
.layer1 {
    height: 100px;
    width: 100px;
    background: #fedfe2;
    border-radius: 30px;
    position: absolute;
    left: 0;
    top: 0;
}

.layer2 {
    height: 100px;
    width: 100px;
    background: #fec5cb;
    border-radius: 30px;
    position: absolute;
    left: 20px;
    top: 0;
}

.layer3 {
    height: 100px;
    width: 100px;
    background: #fc6171;
    border-radius: 30px;
    position: relative;
    left: 40px;
    top: 0;
}

span.wrb_dot {
    width: 10px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 38px;
    right: 27px;
    transform: rotate(0deg);
    display: none;
}

span.wrt_dot {
    width: 15px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 45px;
    right: 24px;
    transform: rotate(-30deg);
    display: none;
}

.starComon {
    animation: shake 10s;
    animation-iteration-count: infinite;
}

.starComon:nth-child(2n)
{
    animation: shake 13s!important;
}

.two_person{animation: shake 11s; animation-iteration-count: infinite;}
.person_cup{animation: shake 13s; animation-iteration-count: infinite;}



@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }


.usericon_1 {
    width: 30px;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin: auto;
    background: #fde9e9;
    opacity: 0;
}

.usericon_2 {
    width: 18px;
    position: absolute;
    bottom: 0;
    top: 18px;
    left: 31px;
    right: 0;
    margin: auto;
    z-index: 10;
    background: #fde9e9;
    opacity: 0;
}

.getmore_l1 {
    width: 150px;
    height: 60px;
    background: #fedbde;
    border-radius: 16px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    opacity: 0;
}

.getmore_l2 {
    width: 100px;
    height: 100px;
    background: #ff5a6c;
    border-radius: 16px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    opacity: 0;
}

.getmore_l3 {
    top: 0; 
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    height: 40px;
    opacity: 0;
}

.rel_div {
    position: relative;
    min-height: 100px;
}
.ar_middle {
    right: 0px!important;
    top: -26%!important;
    bottom: 0!important;
    margin: auto 0!important;
    opacity: 0!important;
    width: 70px!important;
    height: 110px!important;
}
.al_middle {
    left: 0px!important;
    top: -30%!important;
    bottom: 0!important;
    margin: auto 0!important;
    opacity: 0!important;
    width: 70px!important;
    height: 110px!important;
}
.nav_dot {
    width: 6px;
    height: 6px;
    bottom: -17px;
    position: absolute;
    border-radius: 82px;
    background: #242424;
    transform-origin: center;
    right: 0;
    left: 0;
    top:50px;
    margin: auto;
    display: none;
}


/* *********************************************************************** */


/* .facebook, .google {
    vertical-align: middle!important;
    border-style: none!important;
    color: #fff!important;
    font-size: 14px;
    border-radius: 17px !important;
    text-align: center !important;
    outline: none !important;
    width: 100% !important;
    font-family: 'OmnesRegular';
    padding: 10px 5px;
} */
  
    
.facebook{
background-color: #4a66ab;
}

.google {
padding: 0px 18px !important;
background-color: #cc5849!important;
}
.google {
display: inline-flex;}

.address_type_button{
padding: 19px 35px !important;
margin-right: 11px !important;
margin-bottom: 11px !important;
}


/* Css by anshu */
.error_message{
    margin-left: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #AE86E0 !important;
    margin-top: 5px;
    margin-bottom: 0;
    font-family: 'OmnesRegular' !important;
}

.build_your_family_relative{
    color: #4E4E4E !important;
    background: transparent !important;
    border: none !important;
}

.sharegain_swiper img {
width: 100% !important;
border-radius: 10px;
}

.detail_swiper img {
    width: 100% !important;
    border-radius: 10px;
}

/* .sharegain_swiper .swiper-slide-prev img, .swiper-slide-next img {
    margin-top: 24%;
} */

/* .detail_swiper .swiper-slide-prev img, .swiper-slide-next img {
    margin-top: 24%;
} */

.sharegain_swiper img {
border-radius: 20px;
}

.detail_swiper img {
    border-radius: 20px;
    }

.prevbutton
{
position: absolute;
left: 0;
top: 47px;
z-index: 1;
opacity: 0;
cursor: pointer;
height: 53px;
}

.nextbutton
{
position: absolute;
right: 0;
top: 47px;
z-index: 1;
opacity: 0;
cursor: pointer;
height: 53px;
}

/* .contest_process1 div div{
margin-top: -1px;
} */
.phone_verify_backlink{
top: -4px;
position: absolute;
left: -15px;
cursor: pointer;
}

.survey_backlink{
    /* top: 43px; */
    position: absolute;
    left: -15px;
    cursor: pointer; 
}
.user_dob input{
text-transform: lowercase !important;
}

.unknwnchild_content.unknwnchild_content_New div p {
cursor: pointer;
}



  /* ********************************  New updates by 22-july  *************************************** */


  .swiper-slide.slides.swiper-slide-next {
    text-align: right !important;
    }
    .swiper-slide.slides.swiper-slide-prev {
    text-align: left !important;
    }
    .child_count {
        position: fixed;
        bottom: 26px;
        right: 0;
        left: 0;
        z-index: 999999;
        width: 371px;
        margin: 0 auto;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(7px);
    }
    .child_left label {
        font-size: 30px !important;
    display: block !important;
    padding: 14px 5px 14px !important;
    text-align: center;
    font-family: 'OmnesSemiBold';
    line-height: 34px;
    }
    .many_childs {
        padding-top: 35px;
        padding-bottom: 78px;
        /* min-height: 100vh; */
        /* height: 100%; */
    }

    .many_childs::-webkit-scrollbar-track{
        background-color: #F5F5F5;
        border-radius: 10px;
    }
    .many_childs::-webkit-scrollbar{
        width: 5px;
        background-color: #F5F5F5;
    }
    .many_childs::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #dfdfdf), 
        color-stop(0.72, #d2cece), color-stop(0.86, rgba(66, 66, 66, 0.23)));
    }

    .uperImgs img {
        width: 70px !important;
        cursor: pointer;
        margin-right: 8px;
        background: #ffffff;
        padding: 8px 6px;
        border-radius: 20px;
    }
    .freebiesImgs img {
        width: 70px !important;
        cursor: pointer;
        margin-right: 8px;
        background: #ffffff;
        padding: 8px 6px;
        border-radius: 20px;
    }
    .social_activityright a img {
        height: 40px;
        width: 40px;
    }
    .rightsymbol {
        width: initial !important;
        height: initial !important;
    }
    .lcd_check {
        width: 100% !important;
    }
    .lcd_check label {
        margin-bottom: 0px !important;
        float: left !important;
    }
    /* .unknwnchild_content p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #242424;
        opacity: 0.5;
        margin-top: 0px;
    } */
    .custom_Addchildmodel_New input { 
        width: 100%;
        border: none;
        border-bottom: 2px solid #ef7684;
        outline: none;
        font-stretch: normal;
        letter-spacing: normal;
        font-family: 'OmnesRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        display: -ms-flexbox;
        align-items: center;
        color: #242424;
    }
    .custom_Addchildmodel_New div{
         width: 100% !important;
    }
    .custom_Addchildmodel_New div::before, .custom_Addchildmodel_New div::after{
         content: none !important;
    }
    .cancelBtns {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        color: #242424;
    }
    .enter_manully button {
        width: 50% !important;
        margin-top: 0px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        align-items: center !important;
        text-align: center !important;
        padding: 12px 0px !important;
        border-radius: 30px !important;
        font-family: 'OmnesSemiBold' !important;
        outline: none !important;
        color: #4E4E4E !important;
        min-height: 52px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #ffffff !important;
    }
    .enter_manully p {
        margin-bottom: 0px !important;
        
        width: 50%;
    }
    .enter_manully {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    .unknwnchild_content_New {
        background: transparent !important;
        top: 0 !important;
        height: 100%;
    }

    @media (min-width: 576px){
    .custom_Addchildmodel_New{
        max-width: 370px !important;
        margin: 1.75rem auto;
      }
    }
    .custom_Addchildmodel_New {
        /* margin-top: 0px !important; */
        mix-blend-mode: normal;
        /* height: 100%; */
        background: #FFFFFF;
        mix-blend-mode: normal;
        opacity: 0.94;
    }
    .unknwnchild_content_New h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        color: #242424;
    }
    .custom_Addchildmodel_New .modal-content, .custom_unknwnchildBody_New, .unknwnchild_content_New {
        height: 100%;
    }
    .add_position{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 16px;
        width: 100%;
        padding: 0px 21px;
    }

    /******************************** update on 23 july *********************************/
    .boarding_submit button.last{
        background: #ee576a !important;
        opacity: 1 !important;
    }

    .boarding_submit{
        text-align: center;
        width: 108%;
        position: absolute;
        left: 0;
        bottom: 18%;
    }

    .boarding_submit2{
        text-align: center;
        z-index: 1;
        width: 85%;
        position: absolute;
        left: 31px;
        bottom: 18%;
    }

    .boarding_submit2 button {
        color: #ffffff;
        font-family: 'OmnesBold';
        outline: none !important;
        margin-top: 0px;
        padding: 12px 34px;
        background: linear-gradient(158.95deg, #FF5A6C -28.17%, #F27381 366.38%);
        border-radius: 18px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        width: 100%;
    }

    .loader_class{
        margin-top: 15%;
        width: 40px !important;
        height: 40px !important;
    }

    .resend_call{
        cursor: pointer
    }

    .all_relative_section{
        height: 320px;
        overflow: auto;
        padding-top: 12px;
    }

    .relative_add_section{
        position: absolute !important;
        width: 100%;
        bottom: 26px !important;
        left: 0 !important;
        right: 0px !important;
    }

    .upper_initials_class{
        width: 40px;
        height: 40px;
        background: #eee;
        margin: auto;
        display: inline-block;
        border-radius: 50% 50%;
        position: relative;
    }

    .one_social_connect{
        display: flex;
        justify-content: flex-end;
    }

    .custom_align {
        margin-left: -15px;
        z-index: 1;
        width: 40px!important;
        height: 40px!important;
        display: inline-block;
        border-radius: 50% 50%;
        position: relative;
        text-align: center !important;
        padding: 5px 0px;
        font-family: 'OmnesSemiBold';
    }

    /******************************** update on 24 july *********************************/
    /* .webMenu{
        position: fixed !important;
        backdrop-filter: blur(5px);
        background-color: hsla(15, 67%, 96%, 0.8) !important;
    } */

    .social_icon a,.social_icon .google,.social_icon .facebook {
        /* display: inline-block; */
        margin: 0px;
        cursor: pointer;
    }
    .social_icon a {
        display: block !important;
        margin: 0px !important;
        cursor: pointer !important;
        text-align: center !important;
    }
    button.google:after {
        left: 55px !important;
        height: 11px !important;
        width: 11px !important;
    }
    .social_icon .google{
        display: block !important;
        width: 100%;
    }

    button.atv_btn {
        background: #fceceb !important;
    }

    .contest_call {
        display: flex !important;
        justify-content: space-between !important;
    }

    a.custom_googleMain img {
        position: absolute;
        left: 35%;
        right: 35%;
        top: 15px;
    }

    .custom_googleMain {
        position: relative;
    }
    .relative_add_section2{
        position: absolute !important;
        width: 100%;
        bottom: 60px !important;
        left: 0 !important;
        right: 0px !important;
    }
    
    .all_relative_section2 {
        height: 320px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-bottom: 12px;
    }

    .cus_green {
        color: #66BD99!important;
        background-color: rgba(244, 249, 245, 0.64)!important;
    }
    .right_arrow{
        width: 25px;
        position: absolute;
        right: 0;
        margin-top: -38px;
        cursor: pointer;
    }

    .address_parent_section{
        height: 320px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .address_child_section{
        position: relative;
        font-size: 12px;        
    }

/**************** 25-july-2019 ****************/

.contests_contentImges {
max-height: 200px;
overflow: hidden;
border-radius: 20px;
}

.freebiesImges {
max-height: 270px;
overflow: hidden;
border-radius: 0px 0px 20px 20px;
/* min-height: 270px; */
}

a.custom_googleMain img {
position: absolute;
left: 35%;
right: 35%;
top: 15px;
z-index: 1000!important;
}
.relative_sec{
position: relative;
}

.width_auto{
    width: auto !important;
    margin-top: 75% !important;
}

.loader{
width: 40px ;
height: 40px !important;
margin: auto;
margin-top: 31%;
left: 50%;
right: 50%;
    }
    .contest_call p {
        cursor: pointer !important;
    }
/* .contest_process1 input{
    margin-right: 46px !important;
    width: 65% !important;
} */

.resend{
    width: 40% !important;
}
.share_pointRght{
    cursor: pointer;
}
/******* 26-july-2019 *********/

/* .MuiInputLabel-formControl {
    transform: translate(0, 1.5px) scale(0.75)!important;
} */
.mt-16{
    margin-top: 16px!important;
}

.contests_dtlImges{
   /* height: 270px;
   overflow: hidden; */
   border-radius: 0px 0px 20px 20px;
}

.profile_initials{
    width: 68px;
    height: 68px;
    text-align: center;
    padding: 20px 0px;
    border-radius: 50% 50%;
    background: #b287de69;
    position: relative;
}
p.profile_initials span {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 114.21%;
    color: #8E53A6;
    text-transform: uppercase;
    font-family: 'OmnesSemiBold';
}
a.profilPlus {
    position: absolute !important;
    width: 26px;
    height: 26px;
    left: 58px;
    top: 39px;
    background: linear-gradient(117.02deg, #F47C6A -28.17%, #F27381 366.38%);
    border-radius: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OmnesSemiBold';
}
.active_sorting{
    color: #4E4E4E !important;
    font-family: 'OmnesSemiBold' !important;
}

.notactive_sorting{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #a6a6a6 !important;
    font-family: 'OmnesRegular';
}

.container_test{
    width: 80%;
    margin: 0 auto;
    min-height: 600px;
}

.video_story{
    width: 100% !important;
    border-radius: 20px;
    height: 181px !important;
}

.react-player__preview{
    border-radius: 20px;
}
#player{
    border-radius: 20px;
}


.video_story iframe{
    border-radius: 20px;
} 

.no_notification{
    text-align: center;
    color: #bf4848;
}

/*****  31-july-2019  *****/
.rm_transform label{
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
}


.skill_query
{
    display: flex;
    justify-content: center;
}
.skill_query span{
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 114.21%;
    text-align: center;
    /* letter-spacing: -0.01em; */
    color: #242424;
    margin-bottom: 0px;
    font-family: 'OmnesRegular';
    margin-top: 10px;
}

  /* 07-Aug-2019 */
  .container11 {
    position: absolute;
    text-align: center;
    width: 325px;
    height: 440px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: none;
}

.container11 div {
    position: absolute;
    /*background: #9CEE02;
    width: 24px;
    height: 24px;
    border-radius: 100%;*/
    box-shadow: "0px 0px 4px 0px #9CEE02";
}

.partical1{
height: 2px;
width: 3px;
background: #f00;
border-radius: unset;
animation: partical1 linear 7s forwards 1s 1;
transform: skew(30deg);
}
.partical2{
height: 5px;
width: 7px;
background: #f00;
border-radius: unset;
animation: partical2 linear 7s forwards 1s 1;
transform: skew(30deg);
}
.partical3{
height: 7px;
width: 5px;
background: #f00;
border-radius: unset;
animation: partical3 linear 7s forwards 1s 1;
transform: skew(30deg);
}
.partical4{
height: 3px;
width: 2px;
background: #f00;
border-radius: unset;
animation: partical4 linear 7s forwards 1s 1;
transform: skew(30deg);
}
.partical5{
height: 5px;
width: 5px;
background: #f00;
border-radius: unset;
animation: partical5 linear 7s forwards 1s 1;
transform: skew(30deg);
}


@keyframes partical1 {
0% {
    transform: rotateY(0deg) translate(0px, 0px) skew(0deg);
    opacity: 1;
}

50% {
    transform: rotateY(60deg) translate(0px, 75px) skew(30deg);
    opacity: 1;
}

100% {
    transform: rotateY(360deg) translate(0px, 150px) skew(20deg);
    opacity: 0;
}
}

@keyframes partical2 {
0% {
    transform: rotateY(60deg) translate(0px, 0px) skew(20deg);
    opacity: 1;
}

50% {
    transform: rotateY(360deg) translate(0px, 75px) skew(30deg);
    opacity: 1;
}

100% {
    transform: rotateY(0deg) translate(0px, 150px) skew(0deg);
    opacity: 0;
}
}

@keyframes partical3 {
0% {
    transform: rotateY(360deg) translate(0px, 0px) skew(20deg);
    opacity: 1;
}

50% {
    transform: rotateY(120deg) translate(0px, 75px) skew(0deg);
    opacity: 1;
}

100% {
    transform: rotateY(0deg) translate(0px, 150px) skew(30deg);
    opacity: 0;
}
}

@keyframes partical4 {
0% {
    transform:  rotateY(-60deg) translate(0px, 0px) skew(30deg);
    opacity: 1;
}

50% {
    transform: rotateX(0deg) translate(0px, 75px) skew(0deg);
    opacity: 1;
}

100% {
    transform: rotateY(60deg) translate(0px, 150px) skew(20deg);
    opacity: 0;
}
}

@keyframes partical5 {
0% {
    transform: rotateY(120deg) translate(0px, 0px) skew(0deg);
    opacity: 1;
}

50% {
    transform: rotateY(0) translate(0px, 100px) skew(20deg);
    opacity: 1;
}

100% {
    transform: rotateY(-120deg) translate(0px, 200px)  skew(-30deg);
    opacity: 0;
}
}

.rotate10{
-ms-transform: rotate(20deg); /* IE 9 */
-webkit-transform: rotate(20deg); /* Safari 3-8 */
transform: rotate(20deg);
}

.rotate20{
-ms-transform: rotate(40deg); /* IE 9 */
-webkit-transform: rotate(40deg); /* Safari 3-8 */
transform: rotate(40deg);
}

.rotate30{
-ms-transform: rotate(60deg); /* IE 9 */
-webkit-transform: rotate(60deg); /* Safari 3-8 */
transform: rotate(60deg);
}

.rotate40{
-ms-transform: rotate(800deg); /* IE 9 */
-webkit-transform: rotate(80deg); /* Safari 3-8 */
transform: rotate(80deg);
}

.rotate50{
-ms-transform: rotate(90deg); /* IE 9 */
-webkit-transform: rotate(90deg); /* Safari 3-8 */
transform: rotate(90deg);
}

.rotate60{
-ms-transform: rotate(100deg); /* IE 9 */
-webkit-transform: rotate(100deg); /* Safari 3-8 */
transform: rotate(100deg);
}

.rotate70{
-ms-transform: rotate(120deg); /* IE 9 */
-webkit-transform: rotate(120deg); /* Safari 3-8 */
transform: rotate(120deg);
}

.rotate80{
-ms-transform: rotate(140deg); /* IE 9 */
-webkit-transform: rotate(140deg); /* Safari 3-8 */
transform: rotate(140deg);
}

.rotate90{
-ms-transform: rotate(160deg); /* IE 9 */
-webkit-transform: rotate(160deg); /* Safari 3-8 */
transform: rotate(160deg);
}


.prizelater {
z-index: 10;
display: inline-flex;
}

.open_prize button{
z-index: 10; 
}

.prizelater label{
z-index: 1; 
}

.program_entry_loader {
    /* width: 40px;
    height: 40px;
    background: #4CAF50;
    margin: auto;
    border-radius: 10px; */
    text-align: center;
    position: absolute;
    top: 0;
    /* bottom: 0; */
    left: 0;
    right: 0;
    opacity: 0;
    height: 120px;
    width: 120px;
    margin: 0 auto;
}

.program_entry_loader .loading {
width: 20px !important;
height: 20px !important;
margin-top: 10px!important;
color: #fff;
}

.program_entry_loader svg {
box-sizing: unset;
}

.rel_block{
position: relative;
}
.notification_count{
cursor: pointer;
width: 40px;
height: 40px;
border-radius: 12px;
background: #FF5A6C;
text-align: center;
display: inline-block;
position: relative;
}
.notification_count span {
position: absolute;
left: 0;
font-style: normal;
right: 0;
top: 0;
font-weight: 600;
bottom: 0;
color: #fff;
margin-top: 7px;
z-index: 2;
}

.notify_rght {
/* width: 75%; */
/* float: right; */
text-align: initial;
}

.nextslides img {
width: 58px;
cursor: pointer;
}

.discover_headRght img{
cursor: pointer;
}

.andres_verified button {
font-weight: 600;
color: #6FCF97;
font-family: Muli,sans-serif;
background: #f4f5f9;
padding: 9px 16px;
text-transform: none;
border-radius: 20px;
margin-top: -16px;
outline: none!important;
}

/*** 09-Aug-2019 ***/

.boarding_dot_particle1 {
top: 15px;
width: 20px;
border: 2px solid #fff;
left: 5px;
border-radius: 2px;
position: absolute;
transform: rotate(45deg);
/* opacity: 0; */
display: none;
}

.boarding_dot_particle2 {
top: 15px;
width: 12px;
border: 2px solid #fff;
left: 0;
right: 0;
margin: auto;
border-radius: 2px;
position: absolute;
transform: rotate(90deg);
/* opacity: 0; */
display: none;
}

.boarding_dot_particle3 {
top: 15px;
width: 20px;
border: 2px solid #fff;
right: 5px;
border-radius: 2px;
position: absolute;
transform: rotate(135deg);
/* opacity: 0; */
display: none;
}

.boarding_dot_particle4 {
top: 0;
width: 12px;
border: 2px solid #fff;
right: 10px;
height: 1px;
bottom: 0; 
margin: auto;
border-radius: 2px;
position: absolute;
transform: rotate(0deg);
/* opacity: 0; */
display: none;
}

.boarding_dot_particle5 {
top: 0;
width: 12px;
border: 2px solid #fff;
left: 10px;
height: 1px;
bottom: 0;
margin: auto;
border-radius: 2px;
position: absolute;
transform: rotate(0deg);
/* opacity: 0; */
display: none;
}

.boarding_dot_particle6 {
width: 20px;
border: 2px solid #fff;
left: 5px;
bottom: 15px;
margin: auto;
border-radius: 2px;
position: absolute;
transform: rotate(135deg);
/* opacity: 0; */
display: none;
}
.boarding_dot_particle7 {
width: 12px;
border: 2px solid #fff;
left: 0;
right: 0;
bottom: 15px;
margin: auto;
border-radius: 2px;
position: absolute;
transform: rotate(90deg);
/* opacity: 0; */
display: none;
}
.boarding_dot_particle8 {
width: 20px;
border: 2px solid #fff;
right: 5px;
bottom: 15px;
margin: auto;
border-radius: 2px;
position: absolute;
transform: rotate(45deg);
/* opacity: 0; */
display: none;
}
.star_contain{
height: 100px;
}
.uperImgs a {
display: inline-block;
height: 59px;
width: 65px;
background: #ffffff;
border-radius: 20px;
line-height: 55px;
}
.uperImgs img {
width: 50px;
cursor: pointer;
}

.uperImgs img {
width: 63px !important;
cursor: pointer;
vertical-align: middle;
border-radius: 20px;
}
.freebiesImgs {
position: absolute;
bottom: -31px !important;
left: 16px;
}
.program_detail_logo{
position: absolute;
/* bottom: -31px;
left: 16px; */
bottom: 16px;
left: 16px;
}
.program_detail_logo a{
display: inline-block;
height: 59px;
width: 65px;
background: #ffffff;
border-radius: 20px;
line-height: 55px;
margin-right: 8px;
}

.program_detail_logo img{
    width: 60px !important;
    padding: 4px 8px;
}

.nav.nav-tabs{
display: flex !important;
justify-content: space-evenly;

}
.custom_prizemodel .modal-content{
overflow: hidden;
}

.skilltestmodel_address .modal-content {
top: 15%;
font-family: 'Montserrat', sans-serif;
min-height: 259px;
background: #ffffff !important;
border-radius: 20px;
left: 0px !important;
padding: 0px 21px;
padding-right: 8px;
}

.shipping_address_main{
height: 600px;
overflow: scroll;
margin-bottom: 40px;
}
.layer3 {
z-index: 9;
}

.successResponse {
color: #4CAF50;
margin-bottom: 10px;
}

.failedResponse{
color: #fc6172;
}

.setting_notify{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fdf2f0;
    border-radius: 15px;
    margin-bottom: 10px;
    min-height: 52px;
    padding: 12px 14px;
    width: 100% !important;
}


.entered_program{
position: absolute;
right: 18px;
bottom: 20px;
background: white;
border-radius: 20px;
padding: 12px;
}
.entered_program a{
color: green;
}

.entered_program{
position: absolute;
right: 18px;
bottom: 20px;
background: white;
border-radius: 20px;
padding: 12px;
}
.entered_program a{
color: green !important;
}

.pending_entry1{
position: absolute;
right: 18px;
bottom: 20px;
background: white;
border-radius: 20px;
padding: 12px;
}

.pending_entry1 a{
color: red !important;
}


/*** 05 Sept 2019 ***/
.review_gap {
padding: 10px 0!important;
}
.review_gap h3 {
margin: 10px 0!important;
}


/*** 16 sept 2019 ***/

.active_program {
margin: auto!important;
position: initial;
bottom: 0;
left: 0;
right: 0;
top: 0;
z-index: 6;
margin-bottom: auto!important;
width: 363px;
height: 50%;
background: transparent!important;
overflow: hidden;
}

.fixed{
position: fixed!important; 
width: 363px;
overflow: hidden;
height: 50%;
top:25%;
}

::-webkit-scrollbar {
width: 0 !important
}
body{
 overflow: -moz-scrollbars-none; 
 -ms-overflow-style: none;

}


.makeitcenter{
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
width: 64px;
height: 64px;
color: #904949 !important;
}

.upload_in {
position: relative;
}
.upload_inner{
vertical-align: middle;
}

div.detailspace_rmv_prc {
min-height: initial!important;
}

.preview_upload img {
opacity: 0.2;
}
.click_to_view{
width: initial!important
}
.active_program .contests_dtlImges {
border-radius: unset;
}

.preview_upload {
transition: all 2s;
animation: slide-up 1s ease;
}

.urgent_main1{
transition: all 2s;
animation: slide-down 1s ease;
}

.fadein_effect{
-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
     -o-animation: fadein 2s; /* Opera < 12.1 */
        animation: fadein 2s;
}

.pendingContest_btn{
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 0.3s; /* Firefox < 16 */
    -ms-animation: fadein 0.3s; /* Internet Explorer */
     -o-animation: fadein 0.3s; /* Opera < 12.1 */
        animation: fadein 0.3s;
}

.enterConst_btn{
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 0.3s; /* Firefox < 16 */
    -ms-animation: fadein 0.3s; /* Internet Explorer */
     -o-animation: fadein 0.3s; /* Opera < 12.1 */
        animation: fadein 0.3s;
}

@keyframes fadein {
from { opacity: 0;}
to   { opacity: 1;}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

@keyframes slide-up {
0% {
    opacity: 0;
    transform: translateX(-20px);
}
100% {
    opacity: 1;
    transform: translateX(0);
}
}

@keyframes slide-down {
0% {
    opacity: 0;
    transform: translateY(-20px);
}
100% {
    opacity: 1;
    transform: translateY(0);
}
}



.min_h132{
min-height: 132px;
}
.CircularProgressbar .CircularProgressbar-path {
stroke: #904949!important;
}

.rvewsPage_dtl {
width: initial!important;
}
.active_program .freebiesImges {
border-radius: unset;
}
.detail_page_width{
width:100%!important;
}
.stryDetail {
width: initial!important;
}
.active_program .dtl_Imges {
border-radius: unset!important;
}

canvas {
position: fixed;
top: 0;
left: 0;
cursor: default;
bottom: 0;
right: 0;
/* max-width: 371px;
height: 100%; */
margin: auto;
}

/* #some-program {
overflow: hidden!important;
} */

.sessionCntntDtails {
width: 100%!important;
}

.notification_pop {
animation: 1s infinite beatHeart;
}

@keyframes beatHeart {
0% {
  transform: scale(1);
}
25% {
  transform: scale(1.1);
}
40% {
  transform: scale(1);
}
60% {
  transform: scale(1.1);
}
100% {
  transform: scale(1);
}
}
/* 
.notification_pop {
animation: bloop 1s linear; 
}
*/

.notification_pop .delay1 {
animation: waves 1s linear infinite;
animation-delay: .1s;
}

.notification_pop .delay2 {
animation: waves 1s linear 1s forwards,
}



.circle {
display: block;
height: 20px;
width: 20px;
border-radius: 50%;
background: #FF5A6C;
margin: 10px;
transition: 5s ease;
position: absolute;
top: 0px;
z-index: 1;
}

@-webkit-keyframes waves {
0% {
  transform: scale(1);
  opacity: 1;
}

100% {
  transform: scale(4);
  opacity: 0;
}
}

@keyframes bloop {
0% {
  transform: scale3d(1, 1, 1);
}
30% {
  transform: scale3d(1.25, 0.75, 1);
}
40% {
  transform: scale3d(0.75, 1.25, 1);
}
50% {
  transform: scale3d(1.15, 0.85, 1);
}

65% {
  transform: scale3d(.95, 1.05, 1);
}
75% {
  transform: scale3d(1.05, .95, 1);
}
100% {
  transform: scale3d(1, 1, 1);
}
}


@media only screen and (max-width: 600px){
  body{
    /* background-color: #f7e3e2 !important;
    background-image: linear-gradient(#ffffff, #f7e3e2) !important; */
    background-color: #f4f4f4 !important;
  }
}
.phone_verify_backlink img {
width: 12px;
}
.survey_backlink img{
    width: 12px;    
}
img.clearcontest_img {
max-width: 32px;
}
.detailback_sectionAdd img {
-webkit-box-shadow: initial !important;
box-shadow: initial !important;
border-radius: initial !important;
}

.cstm_dtl_Imges {
background: #44424278;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100%;
}
.cstm_ImgesCntnt{
position: relative;
}
.cstm_dtlImges {
position: relative;
border-radius: 0px 0px 20px 20px !important;
}

/* .TermCon_model{
  margin: 0px !important;
} */

.TermCon_model .modal-content{
    color: #242424;
    top: 0px !important;
    border-radius: 0px !important;
}

.TermCon_Body{
padding-top: 58px;
}

.TermCon_model .sharegain_up{
position: fixed;
width: 100%;
left: 0;
top: 31px;
}

.terms_content{
height: 100vh;
/* max-height: 450px; */
overflow: scroll;
}
.sharegain_up {
z-index: 1;
}
.sharegain_backlink {
z-index: 9;
}

/* .terms_all{
    font-style: normal !important;
    font-weight: 550 !important;
    font-size: 11px !important;
    text-align: center !important;
    color: #242424 !important;
} */

#onlinecode{
    color: #719E7F;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    font-family: 'OmnesSemiBold';
}

div.common_main_kid{
height: 100vh;
}

button.upgrade_btn{
color: #66bd99 !important;
}

#barcode {
width: 100% !important;
}

@media screen and (max-width: 414px) {
.barcode_inr label {
    font-size: 3.5vw !important;
}
}
.show_winer2{
padding: 20px 12px !important;
}

.remove_radius {
    border-radius: unset;
}

.google span {
    display: block !important;
}
.fa-facebook:before {
    content: "\f09a";
    position: absolute !important;
    left: 55px !important;
    top: 14px !important;
}
.social_icon button.facebook {
    position: relative;
}
.socialLoginBtns {
    padding: 0px 12px;
}
@media screen and (max-width: 320px){
  .fa-facebook:before, button.google:after {
    left: 42px !important; 
  }
}
.stp2_smoke, .entrDob {
    display: flex;
    align-items: center;
}
/* .stp2_smokeRght, .entrDobUsr {
    width: 100%;
    text-align: left;
    padding-left: 10px;
} */
.stp2_smokeRght {
    width: 100%;
    text-align: left;
}
.stp2_smokeRght label {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    font-family: 'OmnesSemiBold';
    color: #a6a6a6;
    margin-top: 0px; 
    margin-bottom: 6px;
    padding-left: 4px;
    line-height: 20px;
}
.steperActvLbl label {
    color: #904949 !important;
}
.stp2_smokeRght button {
    color: #a6a6a6;
    font-family: Muli,sans-serif;
    outline: none!important;
    padding: 14px 5px;
    background: #f5f5f5;
    border-radius: 13px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    margin-right: 6px;
    width: 47%;
    display: inline-block;
}
.stp2_smokeRght button:last-child{
    margin-right: 0px !important;
}
.entrDobUsr div {
    width: 100% !important;
    margin-bottom: 0px;
}
.entrDobUsr div::before, .entrDobUsr div::after{
    border-bottom: none !important;
}
.entrDobUsr label {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    font-family: 'OmnesSemiBold';
    margin-top: 0px;
    color: #904949 !important;
    padding-left: 0px;
    line-height: 20px;
    margin-bottom: 0px;
}
.entrDobUsr input {
    width: 85%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    border-radius: 0px !important;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    align-items: center;
    line-height: 19px !important;
    color: #242424;
    padding: 6px 0px !important;
    font-family: 'OmnesRegular';
}
.nextnum_divIner {
    padding: 0px !important;
}

.policyme_nextnum_divIner {
    padding: 0px !important;
}
.entrDob .MuiInput-underline::before, .entrDob .MuiInput-underline::after {
    border-bottom: none !important;
}
.editChildInfo_stper button {
    color: #fff;
    font-family: 'OmnesSemiBold';
    outline: none !important;
    padding: 11px 34px;
    background: linear-gradient(158.95deg,#ff5a6c -28.17%,#f27381 366.38%) !important;
    border-radius: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 85%;
    margin-left: 31px;
    margin: 15px 0;
    margin-bottom: 0px;
    box-shadow: 0 18px 20px -18px #FF5A6C;
} 
.entrDob {
    margin-top: 18px;
}
.instant_hend2 {
    padding: 26px 22px !important;
}
.activeLine1 {
    position: relative;
}
.activeLine1::after {
    visibility: hidden;
    content: " ";
    border-left: 1px dotted red !important;
    position: absolute;
    top: 53px;
    left: 11px;
    z-index: 999 !important;
    min-height: 60px;
}
.activeLine2 {
    position: relative;
}
.activeLine2::after {
    visibility:hidden;
    content: " ";
    border-left: 1px dotted red !important;
    position: absolute;
    top: 58px;
    left: 11px;
    z-index: 999 !important;
    min-height: 77px;
}
.activeLine_smoking {
    position: relative;
}
.activeLine_smoking::after {
    visibility:hidden;
    content: " ";
    border-left: 1px dotted red !important;
    position: absolute;
    top: 58px;
    left: 11px;
    z-index: 999 !important;
    min-height: 77px;
}
.stperActvBtn{
    background: #9bd4bf !important;
    color: #ffffff !important;
}
.activeLine1 svg.MuiStepIcon-active, .activeLine1 svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #eec56d !important;
}
.activeLine2 svg.MuiStepIcon-active, .activeLine2 svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #f19f85 !important;
}
.activeLine3 svg.MuiStepIcon-active, .activeLine3 svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #e27a83 !important;
}

.activeLine_smoking svg.MuiStepIcon-active, .activeLine_smoking svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #f19f85 !important;
}

/* .extragain_tabs ul.nav.nav-tabs {
    background: #f9f2f1;
    border-radius: 20px;
}
.extragain_tabs ul.nav.nav-tabs li {
    display: flex !important;
    align-items: center;
    justify-content: center !important;
} */

/* Next JS css */
.acErrorMsg {
    text-align: center;
    margin-left: 0px !important;
    display: block;
    font-size: 14px;
    font-family: 'OmnesRegular' !important;
    color: #ae86e0 !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    margin-top: 9px !important;
    margin-bottom: 8px !important;
}
.verifyHeadOne img {
    cursor: pointer;
}
.address_child_section p {
    cursor: pointer;
}
div.error_messageTimr {
    margin-top: 14px !important;
    color: #AE86E0 !important;
    text-align: center !important;
}
.erorMsgAddon {
    text-align: center;
    margin-top: 15px;
    color: #AE86E0 !important;
}
.sessionAddOns .contests_dtlImges, 
.sessionAddOns .contests_dtlImges img {
    box-shadow: initial !important;
}
.sessionAddOns .freeies_babyImg {
    margin-bottom: 24px;
}
.programLogo .program_detail_logo img {
    width: 60px !important;
    padding: 8px 8px !important;
    box-shadow: 0px 4px 6px 1px #c68b8538;
    border-radius: 22px;
}
.programLogoLft {
    position: initial;
    bottom: initial;
    left: initial;
}
.programLogoRght {
    background: #FFF;
    padding: 8px 10px 8px 8px;
    box-shadow: 0px 4px 6px 1px #c68b8538;
    border-radius: 22px;
    display: flex;
    align-items: center;
}
.programLogoRght .instantDetailsSpc {
    margin-top: 0px;
}
.programLogo {
    position: absolute;
    width: 94%;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    bottom: -5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
.programLogoRght a {
    margin-right: 0px !important;
}
a {
    text-decoration: none !important;
}
.freebies_iner h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 10px 0px 6px;
    color: #4E4E4E !important;
    opacity: initial !important;
    font-family: 'OmnesSemiBold';
}
.signupConectCircle {
    background-image: none;
}
.gain_extra h1,
.gain_extra h2,
.gain_extra h3,
.gain_extra h4,
.gain_extra h5,
.gain_extra h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #4E4E4E;
    width: 90%;
    margin: auto;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 14px;
    font-family: 'OmnesSemiBold';
}
@media screen and (max-width: 767px) {
    .gain_extra h1,
    .gain_extra h2,
    .gain_extra h3,
    .gain_extra h4,
    .gain_extra h5,
    .gain_extra h6 {
        width: 70%;
    }
}
@media screen and (max-width: 599px) {
    .gain_extra h1,
    .gain_extra h2,
    .gain_extra h3,
    .gain_extra h4,
    .gain_extra h5,
    .gain_extra h6 {
        width: 80%;
    }
}
/* End of Next JS css */

.loaderCstm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999999 !important;
    background: #ffffffe8 !important;
    height: 90px;
    width: 90px;
    border-radius: 14px;
    opacity: 1 !important;
}

button.descShwBtn {
    border: none !important;
    background: transparent;
    color: #F47C6A;
    cursor: pointer;
    font-size: 14px;
    font-family: 'OmnesRegular';
    outline: none !important;
    margin-top: 4px;
}

.signupConectCircleNext {
    background-image: none !important;
    padding-bottom: 0px;
}

.sessionAddOnsNxt .freebies_iner {
    padding-bottom: 6px;
}

.sessionAddOnsNxt .ends_date {
    margin-top: 5px;
}

.sessionAddOnsNxt .descp_freebies {
    margin-bottom: 2px;
}

.mobVerfyCstm {
    background: rgb(242, 242, 242, 0.5);
    padding: 7px 8px;
    border-radius: 16px;
    min-height: 52px;
    margin: 24px 0px 0px;
    position: relative;
    display: flex;
    align-items: center;
}

.mobVerfySelect {
    margin-top: 2px !important;
    color: #242424 !important;
}

.mobVerfyCstm .mobVerfySelect:before {
    border-bottom: none !important;
}

.mobVerfyCstm .MuiSelect-select.MuiSelect-selectMenu {
    color: #242424;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'OmnesMedium';
    padding-right: 0px;
}

.mobVerfyCstm input {
    border-bottom: none !important;
    color: #4E4E4E;
    font-size: 18px;
    line-height: 22px !important;
    font-weight: 500;
}

.mobVerfyCstm input {
    width: 88%;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    outline: none;
    font-stretch: normal;
    text-transform: capitalize;
    border-radius: 0px !important;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    align-items: center;
    line-height: 21px !important;
    height: 34px !important;
    color: #242424;
    padding: 6px 0px !important;
    font-family: 'OmnesMedium';
    letter-spacing: 1px;
}

.mobVerifySection label {
    visibility: hidden;
}

.entrMobDgt label {
    color: #F47C6A;
    display: block !important;
    margin-bottom: 0px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-family: 'OmnesSemiBold';
}

.mobVerfyCstmUpr span {
    color: #719E7F;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 138.05%;
    letter-spacing: 0.16px;
    display: block;
    font-family: 'OmnesRegular';
    margin-top: 5px;
}

.mobCardDetail p {
    border-radius: 0px 0px 20px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #242424;
    margin-top: 0px;
    margin-bottom: 0;
    padding: 0 40px;
    opacity: 0.5;
    letter-spacing: .02em;
    font-family: 'omnesregular';
}

.smsCardInfo {
    margin: 26px 0px 22px;
}

.smsCardDetailUpr {
    background: #ffffff96 !important;
    border-radius: 20px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 4%);
}

.smsCardDetail {
    border-radius: 20px;
    background: #fff !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10196078431372549);
    padding: 26px 22px;
}

.mobWithUpr {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 12px 0px 22px;
}

.mobWithUpr a {
    outline: none !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    box-shadow: 0 18px 20px -18px #ff5a6c;
    background: #f47c6a;
    border-radius: 30px !important;
    font-family: 'OmnesSemiBold';
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    color: #fff !important;
    padding: 13px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    box-shadow: 0 20px 14px -21px #000000a3;
    cursor: pointer;
}
.mobCardDetail {
    margin-top: 25px;
}
.mobVerifySection label {
    margin-bottom: 8px !important;
}
.mobVerfyMask img{
    position: absolute;
    right: 14px;
    top: 19px;
    cursor: pointer;
}
div.phone_form_class {
    min-width: 34px !important;
    text-align: center;
}
.phone_form_class svg {
    display: none;
}
.cmnTabPrgrm img, .program_detail_logo img, .instant_details a img,
.contests_dtlImges img, .discover_image, .addSliderBg img {
    aspect-ratio: auto 640 / 360;
}
.gain_extra h1, .gain_extra h2, .gain_extra h3,
.gain_extra h4, .gain_extra h5, .gain_extra h6 {
    width: 310.52px !important;
    height: 42px !important;
}

.cardBnrInfo .program_detail_logo {
    position: initial;
}
.cardBnrInfo {
    position: absolute;
    width: 94%;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    bottom: -5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
.programTypeUpr {
    background: #FFF !important;
    padding: 8px 8px 8px 8px !important;
    box-shadow: 0px 4px 6px 1px #c68b8538;
    border-radius: 22px;
    display: flex;
    align-items: center;
    margin-right: 0px;
}
.cardBnrInfo .instant_tags img {
    height: 32px !important;
    width: 32px !important;
    margin-bottom: 0px !important;
    margin-right: 7px;
}

.cardBnrInfo .program_detail_logo img {
    box-shadow: 0px 4px 6px 1px #c68b8538 !important;
}
.programTypeUpr a.bonusTagNw {
    margin-left: 2px;
}
.programTypeUpr a.instant_tags {
    display: flex;
    align-items: center;
}
.signupConectCircleNxt {
    background: none !important;
    padding-bottom: 0px !important;
}
.bonusTagNwNxt img.prgrmSetLogoNxt {
    height: 24px !important;
    width: 24px !important;
}
.common_main_space {
    margin-bottom: 0px !important;
}
.verifyOtp label {
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.05%;
    letter-spacing: 0.14px;
    opacity: 0.5;
    font-family: 'OmnesRegular';
    display: block;
    margin-bottom: 8px;
}
.verifyOtp input {
    width: 100%;
    border: none;
    outline: none;
    font-stretch: normal;
    text-transform: capitalize;
    border-radius: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    align-items: center;
    border-right-color: initial;
    color: #242424;
    padding: 6px 14px;
    font-family: 'OmnesMedium';
    letter-spacing: 1px;
    background: rgb(242, 242, 242, 0.5);
    min-height: 50px;
}
.mobVerfyCstmUpr {
    margin-top: 18px;
}
.otpMsgSend {
    margin-top: 20px;
    margin-bottom: 32px;
}
.otpMsgSend p {
    border-radius: 0px 0px 20px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #242424;
    margin-top: 0px;
    margin-bottom: 0;
    padding: 0 40px;
    opacity: 0.5;
    letter-spacing: .02em;
    font-family: 'omnesregular';
}
.otpMsgSend label {
    color: rgba(36, 36, 36, 0.50);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    font-family: 'OmnesSemiBold';
    display: block;
    margin-top: 16px;
    margin-bottom: 0px;
}
.otpTermCondition p {
    color: rgba(36, 36, 36, 0.50);
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-family: 'OmnesRegular';
    opacity: 0.5;
}
.otpTermCondition p:last-child {
    opacity: initial !important;
    margin-bottom: 0px !important;
}
.otpTermCondition p:last-child a {
    color: #242424 !important;
}
.otpButtons button {
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    width: 100%;
    min-height: 47px;
    border-radius: 30px;
    font-family: 'OmnesSemiBold';
    background: transparent !important;
    border: none;
    outline: none !important;
}
button.otpButonSend {
    background: #F47C6A !important;
    color: #FFF !important;
    margin-top: 10px;
    margin-bottom: 30px;
    box-shadow: 0 18px 20px -18px #ff5a6c;
}
.otpButtons img {
    margin-right: 7px;
}
button.otpButonConct {
    border: 1px solid rgba(0, 0, 0, 0.10) !important;
    background: #FFF;
}
label.resendBtns {
    color: #F47C6A !important;
}
img.verifyHeadAdCls {
    right: 0px !important;
    left: initial !important;
}

/* .sharegain_swiper .swiper-slide-active img.slideImg {
    min-height: 186px;
    min-width: 186px;
} */
.cmnTabPrgrm img {
    min-width: 68px;
    min-height: 68px;
}
.discover_icon_divAdd img {
    width: 50px !important;
    top: 12%;
    min-height: initial !important;
}
.sharegain_swiper .swiper-slide-prev img, 
.sharegain_swiper .swiper-slide-next img {
    padding-top: 0px !important;
}
.extragain_tabs ul {
    min-height: 112px;
}
.extragain_tabs a.nav-link {
    position: relative;
    height: 100%;
}

.cmnTabPrgrm label {
    position: absolute;
    bottom: 2px;
    left: 0px;
    right: 0px;
}

.extragain_tabs.fix_main ul.nav.nav-tabs a.nav-link {
    padding-bottom: 31px !important;
}
html, body, div#__next {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
.buzSubActvGD {
    position: relative;
}
.buzSubActvGD:before {
    background-image: url('/images/rght2.png');
    width: 32px;
    height: 32px;
    content: '';
    position: absolute;
    left: 7px;
    top: 9px;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #64c09b;
    border-radius: 36px;
    background-color: #64c09b;
}

.terms_contentNxt ul li {
    list-style-type: none;
    margin-top: 14px;
    margin-bottom: 8px;
    line-height: 138%;
}

.terms_contentNxt li b {
    margin-right: 8px;
    font-family: 'OmnesSemiBold';
    font-weight: 600;
    color: #4E4E4E;
}

.terms_contentNxt ul li a , .terms_contentNxt p{
    color: #242424 !important;
    font-style: normal;
    font-size: 14px;
    line-height: 138%;
    opacity: .7;
    font-family: "OmnesRegular";
    line-height: 138%;
}

.addCstmPara {
    padding-bottom: 28px;
}

.addCstmPara p {
    padding: 0px !important;
}

a.cursor_pointer2 {
box-shadow: 0 20px 19px -21px #C3C3C3 !important;
border: 1px solid rgba(0, 0, 0, 0.10);
background: #FFF !important;
color: #4E4E4E !important;
}

/* 
.swiper-slide {
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0.5; 
    transform: scale(0.8); 
  } */
  
  /* .swiper-slide-active {
    opacity: 1 !important; 
    transform: scale(1.2) !important; 
  } */
  
  /* .swiper-wrapper {
    display: flex;
    justify-content: center; 
  } */
  
  /* .swiper-slide-next,
  .swiper-slide-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  } */
  
  /* .swiper-slide-prev {
    left: 10px; 
  }
  
  .swiper-slide-next {
    right: 10px; 
  } */



  .carousel-container {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
  


  /* .swiper-slide.swiper-slide-next, .swiper-slide.swiper-slide-prev {
    width: 20% !important;
}

.swiper-slide.swiper-slide-active {
    width: 60% !important;
}

.sharegain_swiper .swiper-slide-prev {
    margin-left: -47px;
}
.swiper-slide.swiper-slide-next {
    margin-right: -47px;
} */


.sharegain_swiper .swiper-slide-next img, 
.sharegain_swiper .swiper-slide-prev img {
    /* width: 130px !important;
    height: 130px !important;
    margin-top: 26%;
    transform: scale(0.7);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; */
    outline: none;
}
.swiper-slide.swiper-slide-active {
    height: 185.5px !important;
    width: 185.5px !important;
}
.swiper-slide.swiper-slide-prev {
    margin-left: -30px;
}

.loaderCenter {
    background: #ffffffe8 !important;
    height: 90px;
    width: 90px;
    border-radius: 14px;
    display: flex;
    text-align: center;
    margin: 0 auto !important;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.main_site_load svg {
    height: 190px !important;
    width: 190px !important;
}

.perksTagBtn {
    margin-right: 5px;
}

.perksTagBtnAddOns img{
    height: 32px !important;
    width: 32px !important;
    margin-bottom: 0px !important;
    margin-right: 7px;
}


.freebies_iner label {
    margin: 0px 0px 6px;
}